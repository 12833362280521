import {AuthModel} from './auth.model';
import {AddressModel} from './address.model';
import {SocialNetworksModel} from './social-networks.model';
import {Role} from './role.model';
import {UserStatus} from "../../../pages/enums/UserStatus";

export class UserModel extends AuthModel {
  id: number;
  username: string;
  password: string;
  fullName: string;
  email: string;
  client: string | undefined;
  status: string;
  enabled: boolean;
  createdDate: Date;
  updatedDate: Date;
  pic: string;
  role: Role;
  occupation: string;
  companyName: string;
  phone: string;
  address?: AddressModel;
  socialNetworks?: SocialNetworksModel;
  userType?: string;
  accountToLink?: string
  countryAccount?: string[];
  // personal information
  firstname: string;
  lastname: string;
  website: string;
  // account information
  language: string;
  timeZone: string;
  communication: {
    email: boolean;
    sms: boolean;
    phone: boolean;
  };
  // email settings
  emailSettings?: {
    emailNotification: boolean;
    sendCopyToPersonalEmail: boolean;
    activityRelatesEmail: {
      youHaveNewNotifications: boolean;
      youAreSentADirectMessage: boolean;
      someoneAddsYouAsAsAConnection: boolean;
      uponNewOrder: boolean;
      newMembershipApproval: boolean;
      memberRegistration: boolean;
    };
    updatesFromKeenthemes: {
      newsAboutKeenthemesProductsAndFeatureUpdates: boolean;
      tipsOnGettingMoreOutOfKeen: boolean;
      thingsYouMissedSindeYouLastLoggedIntoKeen: boolean;
      newsAboutMetronicOnPartnerProductsAndOtherServices: boolean;
      tipsOnMetronicBusinessProducts: boolean;
    };
  };

  setFullName() {
    this.fullName = this.username + " " + this.lastname;
  }

  setStatusFromEnabled() {
    if (this.enabled) {
      this.status = UserStatus.ACTIVE;
    } else this.status = UserStatus.INACTIVE;
  }

  setUser(_user: unknown) {
    const user = _user as UserModel;
    this.id = user.id;
    this.username = user.username || '';
    this.password = user.password || '';
    this.fullName = user.fullName || '';
    this.lastname = user.lastname || '';
    this.email = (user.email).toLowerCase() || '';
    this.pic = user.pic || './assets/media/users/default.jpg';
    this.role = user.role || '';
    this.occupation = user.occupation || '';
    this.companyName = user.companyName || '';
    this.phone = user.phone || '';
    this.address = user.address;
    this.socialNetworks = user.socialNetworks;
    this.userType = user.userType || '';
    this.accountToLink = user.accountToLink || '';
    this.countryAccount = user.countryAccount || [];
  }

  setUserFromFormValues(formValues: any) {
    this.username = formValues.username;
    this.lastname = formValues.lastname;
    this.email = (formValues.email).toLowerCase();
    this.role = new Role(formValues.role);
    this.userType = formValues.userType;
    this.accountToLink = formValues.accountToLink;
    this.countryAccount = formValues.countries|| [];
  }

  toJSON() {
    return {
      username: this.username,
      email: (this.email).toLowerCase(),
      role: this.role,
      userType: this.userType,
      accountToLink: this.accountToLink,
      lastname: this.lastname,
      createdDate: this.createdDate,
      countryAccount: this.countryAccount
    };
  }
}
