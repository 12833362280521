import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {UserModel} from '../../models/user.model';
import {environment} from '../../../../../environments/environment';
import {AuthModel} from '../../models/auth.model';

const API_USERS_URL = `${environment.apiUrl}/v1/auth`;

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  constructor(private http: HttpClient) {
  }

  // public methods
  login(email: string, password: string): Observable<any> {
    return this.http.post<AuthModel>(`${API_USERS_URL}/login`, {
      email,
      password
    });
  }

  // CREATE =>  POST: add a new user to the server
  createUser(user: UserModel): Observable<UserModel> {
    return this.http.post<UserModel>(`${API_USERS_URL}/user/save`, user);
  }

    // UPDATE =>  POST: update existing user
    updateUser(user: UserModel): Observable<UserModel> {
      return this.http.put<UserModel>(`${API_USERS_URL}/user/update`, user);
    }

    // DELETE =>  DELETE: delete existing user
    deleteUser(id: any): Observable<any> {
      return this.http.delete(`${API_USERS_URL}/user/delete/${id}`, {});
    }

  // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
  forgotPassword(email: string): Observable<any> {
    return this.http.post(`${API_USERS_URL}/forgot-password?email=${email}`,{},{responseType: 'text'});
  }

  getUserByToken(token: string): Observable<UserModel> {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.http.get<UserModel>(`${API_USERS_URL}/user`, {
      headers: httpHeaders,
    });
  }

  getUsers(): Observable<UserModel[]> {
    return this.http.get<UserModel[]>(`${API_USERS_URL}/users`);
  }

  updatePassword(email: string, password: string): Observable<any> {
    return this.http.put<AuthModel>(`${API_USERS_URL}/new-password`, {
      email,
      password
    });
  }

  checkTokenExpiration(token: string): Observable<any> {
    return this.http
      .get<any>(`${API_USERS_URL}/check-token-expiration?token=${token}`,
        {observe: 'response'}
      );
  }

  getUsersCountries(linkedAccount: string): Observable<any>{
    return this.http.post<UserModel>(`${API_USERS_URL}/users-countries?linkedAccount=${linkedAccount}`, {
    });
  }
}
