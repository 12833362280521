import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
// #fake-end#
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {HttpClientInMemoryWebApiModule} from 'angular-in-memory-web-api';
import {ClipboardModule} from 'ngx-clipboard';
import {TranslateModule} from '@ngx-translate/core';
import {InlineSVGModule} from 'ng-inline-svg';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthService} from './modules/auth';
import {environment} from 'src/environments/environment';
import {NgxEchartsModule} from 'ngx-echarts';
import { ReactiveFormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
// #fake-start#
import {FakeAPIService} from './_fake';
import {AuthHeaderInterceptor} from 'src/app/Http Interceptor/auth-header-interceptor';
import * as Sentry from "@sentry/angular";
import {BrowserTracing} from "@sentry/tracing";
import {Router} from "@angular/router";
import { ToastrModule } from 'ngx-toastr';
import { TreeModule } from '@circlon/angular-tree-component';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';



function appInitializer(authService: AuthService) {
  return () => {
    return new Promise((resolve) => {
      authService.getUserByToken().subscribe().add(resolve);
    });
  };
}

Sentry.init({
 //dsn: "https://1d0c580be9f148f58fdfe85c2f9e6ce3@o1415196.ingest.sentry.io/6755677",
  // integrations: [
  //   new BrowserTracing({
  //     tracingOrigins: ["localhost", "https://dev.reporting.etail-agency.com"],
  //     routingInstrumentation: Sentry.routingInstrumentation,
  //   }),
  // ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  // tracesSampleRate: 1.0,
  // environment: environment.environment,
});

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    ToastrModule.forRoot({timeOut: 30000}),
    HttpClientModule,
    ReactiveFormsModule,
    NgMultiSelectDropDownModule,
    ClipboardModule,
    NgxEchartsModule,
    NgbAccordionModule,
    // #fake-start#
    environment.isMockEnabled
      ? HttpClientInMemoryWebApiModule.forRoot(FakeAPIService, {
        passThruUnknownUrl: true,
        dataEncapsulation: false,
      })
      : [],
    // #fake-end#
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    NgbModule,
    TreeModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHeaderInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
