import { Injectable, Injector } from "@angular/core";
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpErrorResponse,
    HttpHandler,
    HttpEvent
} from '@angular/common/http';

import { throwError } from 'rxjs';

import { Observable } from 'rxjs';
import { Router } from '@angular/router'
import { catchError } from "rxjs/operators";
import { AuthService } from "../modules/auth";

@Injectable()
export class AuthHeaderInterceptor implements HttpInterceptor {
    constructor(private router: Router,private injector: Injector){}
    
    logout() {
        const auth = this.injector.get(AuthService);
        auth.isExpired = true;
        auth.logout();
     }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let authToken;
        let values = JSON.parse(localStorage.getItem("v8.0.35-authf649fc9a5f55")||'{}');
        for (let k in values){
            if (k === 'authToken') {
                authToken = values[k];
            }
        }
        if(authToken) {
                let authReq = req.clone({
                    setHeaders: {
                        //Accept: 'application/json',
                     //   'Content-Type': 'application/json',
                        Authorization: `Bearer ${authToken}`
                    }
                });
           

            return next.handle(authReq)
                .pipe(
                    catchError(err => {
                        // onError
                        if (err instanceof HttpErrorResponse && err.status === 403) {
                            this.logout();
                        }
                        return throwError(err);

                    }) as any
                );
        }  else {
            console.log("not authToken found in localStorage");
            return next.handle(req);
        }
    }
}