import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {catchError, finalize, map, switchMap} from 'rxjs/operators';
import {UserModel} from '../models/user.model';
import {AuthModel} from '../models/auth.model';
import {AuthHTTPService} from './auth-http';
import {environment} from 'src/environments/environment';
import {Router} from '@angular/router';
import { UserRoles } from 'src/app/pages/enums/UserRoles';
import { Role } from '../models/role.model';

export type UserType = UserModel | undefined;

function getWindow():any {
  return window;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  // public fields
  currentUser$: Observable<UserType>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserType>;
  isLoadingSubject: BehaviorSubject<boolean>;
  isExpired: boolean = false;

  get currentUserValue(): UserType {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserType) {
    this.currentUserSubject.next(user);
  }

  constructor(
    private authHttpService: AuthHTTPService,
    private router: Router
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserType>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    const subscr = this.getUserByToken().subscribe();
    this.unsubscribe.push(subscr);
  }

  // public methods
  login(email: string, password: string): Observable<UserType> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.login(email, password).pipe(
      map((auth: AuthModel) => {
        return this.setAuthFromLocalStorage(auth);

      }),
      switchMap(() => this.getUserByToken()),
      catchError((err) => {
        console.error('err', err);

        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  logout() {
    localStorage.clear();
    this.currentUserSubject.next(undefined)
    this.router.navigate(['/auth/login'], {
      queryParams: {},
    });
  }

  getUserByToken(): Observable<UserType> {
    const auth = this.getAuthFromLocalStorage();

    if (!auth || !auth.authToken) {
      return of(undefined);
    }

    this.isLoadingSubject.next(true);
    return this.authHttpService.getUserByToken(auth.authToken).pipe(
      map((user: UserType) => {
        if (user) {
          this.currentUserSubject.next(user);
        } else {
          this.logout();
        }
        return user;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  getUsers(): Observable<UserModel[]> {
    return this.authHttpService.getUsers();
  }

  getUsersCountries(linkedAccount: string): Observable<UserModel[]> {
    return this.authHttpService.getUsersCountries(linkedAccount);
  }

  registration(user: UserModel): Observable<any> {
    return this.authHttpService.createUser(user).pipe(
      map((createdUser: UserModel) => {
        return createdUser;
      }),
    );
  }

  delete(userId: any): Observable<any> {
    return this.authHttpService.deleteUser(userId);
  }

  update(user: UserModel): Observable<any> {
    return this.authHttpService.updateUser(user).pipe(
      map((updatedUser: UserModel) => {
        return updatedUser;
      }),
    );
  }

  chekIfCurrUserHasExpectedRole(expectedRoles?: UserRoles[] | undefined): boolean{
    let currentUserRoles = this.currentUserValue?.role as Role;
    if(expectedRoles != undefined ){
        return expectedRoles.some(expectedRole =>  currentUserRoles.name === expectedRole);
    }
    return true;
  }


  forgotPassword(email: string): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .forgotPassword(email)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  private setAuthFromLocalStorage(auth: AuthModel): boolean {
    if (auth && auth.authToken) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  private getAuthFromLocalStorage(): AuthModel | undefined {
    try {
      const lsValue = localStorage.getItem(this.authLocalStorageToken);
      if (!lsValue) {
        return undefined;
      }

      return JSON.parse(lsValue);
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  updatePassword(email: string, password: string): Observable<UserType> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.updatePassword(email, password);
  }

  checkTokenExpiration(token: string): Observable<any> {
    return this.authHttpService
      .checkTokenExpiration(token);
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

get nativeWindow() {
  return getWindow();
}


}
