export class Role {

  constructor(private _name: string) {
  }

  get name(): string {
    return this._name;
  }

  set name(name: string) {
    this._name = name;
  }

  toJSON() {
    return {
      name: this.name
    };
  }
}

